import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

import CTARightArrow from "@images/img-min/ctaRightArrow.svg"

const Solutions = ({ screenshot, backgroundImage }) => {
  const image = convertToBgImage(getImage(backgroundImage))
  return (
    <BackgroundImage {...image} className="climate-risk-solutions-background">
      <section className="climate-risk-solutions">
        <div className="container">
          <h2>Climate Risk Solutions</h2>
          <p>
            ClimateCheck provides detailed climate risk data and reports for
            individual properties, portfolios, and geographic corridors to
            assess your current and future exposure to extreme weather events.
          </p>
          <Link to="/solutions" className="link-btn">
            Explore Solutions <CTARightArrow />
          </Link>
          <GatsbyImage
            image={screenshot.childImageSharp.gatsbyImageData}
            alt="Diagrams and maps about climate change risk analysis"
          />
        </div>
      </section>
    </BackgroundImage>
  )
}

export default Solutions
